var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-divider'),_c('div',{staticClass:"text-subtitle-1 mb-4 mt-4"},[_vm._v(" You can collect statistical data if you schedule this test. ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('associatedOption',{ref:"collectSelector",attrs:{"type":"select","fieldAttributes":{
          type: 'select',
          associatedOption: _vm.collectOptions,
          name: 'Collect statistics',
          setEmpty: false,
          ignoreEmptyAdd: true,
          sort: false,
        },"fieldAttrInput":{ class: 'required',id: 'collect_statistics_select' },"templateContent":_vm.result},model:{value:(_vm.collectTime),callback:function ($$v) {_vm.collectTime=$$v},expression:"collectTime"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }