<template>
  <div>
    <v-divider> </v-divider>
    <div class="text-subtitle-1 mb-4 mt-4">
      You can collect statistical data if you schedule this test.
    </div>
    <v-row>
      <v-col cols="6" md="4">
        <associatedOption
          type="select"
          :fieldAttributes="{
            type: 'select',
            associatedOption: collectOptions,
            name: 'Collect statistics',
            setEmpty: false,
            ignoreEmptyAdd: true,
            sort: false,
          }"
          :fieldAttrInput="{ class: 'required',id: 'collect_statistics_select' }"
          v-model="collectTime"
          ref="collectSelector"
          :templateContent="result"
        ></associatedOption>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import associatedOption from "@/commonComponents/associatedOption.vue";
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    result: {},
  },
  components: { associatedOption },
  data() {
    return {
      collectOptions: [
        ["monitoring_time", "Use the timestamp of the scheduled time"],
        ["now", "Use the timestamp of test step execution time"],
        ["no_statistics", "Don't save statistical data"],
      ],
    };
  },
  created() {
    if (
      typeof this.value == "undefined" ||
      typeof this.value.collectTime == "undefined"
    ) {
      this.$emit("input", { collectTime: "monitoring_time" });
    }
  },
  computed: {
    collectTime: {
      get() {
        return this.value.collectTime;
      },
      set(newVal) {
        this.$set(this.value, "collectTime", newVal);
        this.$emit("input", this.value);
      },
    },
  },
};
</script>
